import 'leaflet';
import 'leaflet-boundary-canvas';

// import leafletCss from 'leaflet/dist/leaflet.css';

import palestine from "./countries/palestine.json";
import israel from "./countries/israel.json";
import {win} from "leaflet/src/core/Browser";

const loadMapAssets = () => {
    return new Promise((resolve) => {
        if (typeof window.application._leaflet_loaded !== 'undefined' && window.application._leaflet_loaded) {
            resolve();
        }
        window.application._leaflet_loaded = true;
        const s = document.createElement('link');
        s.rel = 'stylesheet';
        s.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
        s.setAttribute('integrity', 'sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==');
        s.setAttribute('crossorigin', '');
        document.head.appendChild(s);

        resolve();

    });
}

export const RenderMap = async (mapId, mapProps) => {
    const MapBox = $(`#${mapId}`);
    if (MapBox.hasClass('initialized')) {
        return;
    }

    MapBox.addClass('initialized');

    const initMap = loadMapAssets();
    await initMap;

    let zoom = 8.299999999999999;
    let type = MapBox.data('type') || 'forecast-map';
    if (!type) {
        type = 'forecast-map';
    }

    const mapWrapper = MapBox.closest('.weather-map__wrapper');
    const mapCenter = [
        31.608113293564527,
        34.87995266710697
    ];

    var map = L.map(mapId, {
        // zoomDelta: 0.1,
        // zoomSnap: 0,
        center: mapCenter,
        minZoom: 8,
        maxZoom: 9.2
    });

    // osmUrl = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
    var osmUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        osmAttribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';

    $.each(window.application._weather_data, function (indx, city) {
		if(typeof city.forecasts === 'undefined' || typeof city.forecasts[0] === 'undefined'){
			return;
		}
		
        const forecast = city.forecasts[0];
		
        let iconOptions = {};
        if (type === 'wind-direction-map') {
            iconOptions = {
                iconSize: [],
                className: 'map-weather-icon-box',
                html: `
<div class="map-weather-icon-wrapper map-weather-${type}" data-lat="${city.geometry.lat}" data-lng="${city.geometry.lng}" title="${forecast.wind.direction.title}">
    <div class="map-weather-icon__temp-wrapper">
        <div class="map-weather-icon__img map-weather-icon__img__wind"><img src="${window.application.url}/weather-icons/png/wind/${forecast.wind.direction.code}.png" alt="" width="15"></div> 
    </div>
    <div class="map-weather-icon__city">${city.title}</div>

</div>
            `
            };
        } else {
			
            iconOptions = {
                iconSize: [],
                className: 'map-weather-icon-box',
                html: `
<div class="map-weather-icon-wrapper map-weather-${type}" data-lat="${city.geometry.lat}" data-lng="${city.geometry.lng}" title="${forecast.temp.title}">
    <div class="map-weather-icon__temp-wrapper">
        <div class="map-weather-icon__img map-weather-icon__img__forecast"><img src="${window.application.url}/weather-icons/png/d-n/${forecast.temp.key}${forecast.temp.is_night ? 'n' : 'd'}.png" alt="" width="30"></div> 
        <div class="map-weather-icon__temp">${forecast.temp.temp}</div>
    </div>
    <div class="map-weather-icon__city">${city.title}</div>
</div>
            `
            };

        }
        var myIcon = L.divIcon(iconOptions);

        var marker = L.marker([city.geometry.lat, city.geometry.lng], {icon: myIcon});
        if (type !== 'wind-direction-map'){
            marker.bindPopup(`<div class="map-weather-icon__forecast-conditions">
<div class="title">${forecast.temp.title}</div>
<div class="min-tmp">Min: ${forecast.min.temp}</div>
<div class="max-tmp">Max: ${forecast.high.temp}</div>
</div>`);
        }
        marker.addTo(map);

        // L.marker([city.geometry.lat, city.geometry.lng], {icon: greenIcon}).addTo(map).bindPopup(city.title);
        mapWrapper.on('click', '.weather-map__key,.map-weather-icon-wrapper', function (e) {
            e.preventDefault();

            const lat = parseFloat($(this).data('lat'));
            const lng = parseFloat($(this).data('lng'));

            map.flyTo([
                lat,
                lng,
            ], 10.5);

        })

    });

    const countries = [palestine, israel];
    let layers = [];
    var geojson;

    // var layerId = 1;

    function createCountryLayer(country) {
        const layer = new L.TileLayer.BoundaryCanvas(osmUrl, {
            boundary: country,
            attribution: osmAttribution,
        }).addTo(map);

        var countryLayer = L.geoJSON(country);
        map.fitBounds(countryLayer.getBounds());

    }

    for (let i = 0; i < countries.length; i++) {
        createCountryLayer(countries[i]);
    }

    setTimeout(function () {
        map.setView(mapCenter);
            // .setZoom(zoom);
    }, 300);

}