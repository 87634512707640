import {RenderMap} from "./RenderMap";
import '../../styles/leaflet-map.css';

(($) => {
    $(document).ready(function () {
            if (typeof window.application.map_containers === 'undefined') {
                return;
            }

            for (let i = 0; i < window.application.map_containers.length; ++i) {
                const el = $(`#${window.application.map_containers[i]}`);
                if ($(el).is(':visible')) {
                    RenderMap(window.application.map_containers[i]);
                }
            }

            $('body').on('click', '.map-block-tabs .block-tab', function (e) {
                const target = $($(this).attr('href')).find('.weather-map');

                RenderMap(target.attr('id'));

            })

        }
    );
})(jQuery);